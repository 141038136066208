exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-projecten-page-js": () => import("./../../../src/templates/projecten-page.js" /* webpackChunkName: "component---src-templates-projecten-page-js" */),
  "component---src-templates-tuinaanleg-page-js": () => import("./../../../src/templates/tuinaanleg-page.js" /* webpackChunkName: "component---src-templates-tuinaanleg-page-js" */),
  "component---src-templates-tuinarchitectuur-page-js": () => import("./../../../src/templates/tuinarchitectuur-page.js" /* webpackChunkName: "component---src-templates-tuinarchitectuur-page-js" */),
  "component---src-templates-zwembadenvijvers-page-js": () => import("./../../../src/templates/zwembadenvijvers-page.js" /* webpackChunkName: "component---src-templates-zwembadenvijvers-page-js" */)
}

